body {
    overflow: hidden;
    margin: 0; /* Remove default margin */
  }
  
  .image-generator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Set minimum height to 100% of viewport height */
    background-color: #f2f2f2;
  }
  
  .header {
    font-size: 24px;
    background-color: #333;
    color: white;
    padding: 20px;
    width: 100%;
    text-align: center;
  }
  
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 1vh; /* Adjust margin as needed */
  }
  
  .generated-image {
    max-width: 100%;
    max-height: 60vh; /* Adjust maximum height to fit within viewport */
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  .prompt-input {
    padding: 10px;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .generate-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .error {
    color: red;
  }
  