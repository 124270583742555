.image-generator {
  text-align: center;
  margin-top: 50px;
}

input {
  padding: 10px;
  margin: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

img.polaroid {
  width: 300px;
  height: auto;
  border: 10px solid #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}